<template lang="pug">
div(v-if='Inicio')
  //-Pc
  .d-none.d-md-block
    v-carousel(v-model='valor'  hide-delimiters :cycle='true' height='800' :show-arrows='true' width='1200')
      v-carousel-item(v-for='{imagen, titulo, subtitulo}, i in Inicio.carrusel' :key='i')
        v-container(fluid).pa-0 
          v-img( gradient="to top right, transparent , black" height='100%' :src='imagen' contain )
            
          .fila-texto.justify-center.align-items-center
            h1(v-html='titulo' style='line-height : 64px' v-scrollanimation titulo='true').white--text.justify-center.align-items-center
          .fila-texto.justify-center.align-items-center(subtitulo='true' v-scrollanimation titulo='true')
            h1(v-html='subtitulo').white--text.justify-center.align-items-center.font-weight-light
          .fila-texto.justify-center.align-items-center(boton='true' v-scrollanimation )
            v-hover(v-slot='{hover}' )
              button(v-if='hover' style='transform: scale(1.1);' @click='dirigir_a("servicios")').boton.rojo
                h2.white--text Ver más
              button(v-else @click='dirigir_a("servicios")').boton.primario
                h2.white--text Ver más
    .bloque-video
      v-img(:src='Inicio.imagen_video' width='100%' height='700px' gradient="to top right, transparent , black")
        v-container
          v-row.mt-12
            v-col
              video( controls  height='100%' v-scrollanimation video='true' autoplay)
                source(:src='video')
            v-col.justify-center.centrar-columna(v-scrollanimation)
              h1(v-html='Inicio.video_texto').white--text
    
    v-img(:src='Inicio.imagenTestimonios' width='100%' height='1280px' gradient="to top right, transparent , black")
      .bloque-testimonios
        .texto-titulo(v-scrollanimation titulo='true')
          h1(v-html='Inicio.testimoniosBloque.titulo').white--text
        .texto-resumen(v-scrollanimation)
          p(v-html='Inicio.testimoniosBloque.resumen').white--text
        .carrusel-testimonios
          v-carousel(v-model='carruselTestimonios' hide-delimiters :cycle='true' :show-arrows='true' width='1200' height='100%')
            v-carousel-item(v-for='bloqueDeImagenes, i in testimonios' :key='`bloque${i}`')
              v-container(fluid)
                v-row.pa-6.justify-center.align-items-center.pl-8.align-center
                  v-col(v-for='imagen, j in bloqueDeImagenes' :key='`imagenBloque${j}`' ).align-center.justify-center.d-flex
                    v-hover(v-slot='{hover}')
                      v-img(:src='imagen' max-width='320' height='568'
                      :gradient="hover ? 'to top right, transparent , black' : ''")
                        v-container(fluid).pa-4
                            v-icon(v-if='hover' large @click='abrirDialog(imagen)'
                            style="cursor: pointer; position: absolute; top: 50%; left: 0; right: 0; margin-left: auto; margin-right: auto ").white--text fas fa-search-plus

        .texto-llamado-a-accion(v-scrollanimation titulo='true')
          h1(style='font-weight: 300;').white--text ¿Y tú? ¿Esperas seguir siendo un espectador 
          h1(style='font-weight: bolder;').white--text o empezar a mejorar tu vida?
    v-img(:src='Inicio.sobreMi.imagen' width='100%' height='800px' gradient="to top right, transparent , black")
      .bloque-sobre-mi
        .texto-titulo(v-scrollanimation titulo='true')
          h1(v-html='Inicio.sobreMi.titulo').white--text
        .texto-resumen(v-scrollanimation)
          p(v-html='Inicio.sobreMi.resumen').white--text
        .fila-boton(v-scrollanimation boton='true' style="top: 70%")
          .justify-center.align-items-center
            v-hover(v-slot='{hover}' )
              button(v-if='hover' style='transform: scale(1.1);' @click='dirigir_a("sobreMi")').boton.rojo
                h1.white--text Conóceme
              button(v-else @click='dirigir_a("sobreMi")').boton.primario
                h1.white--text Conóceme
    
    v-dialog(v-model='dialog' width='600')
      v-img(:src='urlDialog' max-width='600' height='1000')
        v-container(fluid style="height: 100px;").pa-4.primario
          v-icon(x-large style="cursor: pointer; position: absolute; top: 2%; left: 0; right: -80%"
          @click='dialog = false').rojo--text fas fa-times

  //-Móvil
  .d-md-none
    v-carousel(v-model='valor' hide-delimiters :cycle='true' :show-arrows='true' height=570)
      v-carousel-item(v-for='{imagen, titulo, subtitulo}, i in Inicio.carrusel' :key='i')
        v-container(fluid).pa-0 
          v-img(gradient="to top right, transparent , black"  :src='imagen' height=570 )
            .fila-texto.justify-center.align-items-center(movil='true')
              h3(v-html='titulo' style='line-height : 28px' v-scrollanimation titulo='true').white--text.justify-center.align-items-center
            .fila-texto.justify-center.align-items-center(v-scrollanimation titulo='true' movil-subtitulo='true')
              h3(v-html='subtitulo' style='line-height : 28px').white--text.justify-center.align-items-center.font-weight-light
            .fila-texto(v-scrollanimation boton-movil='true').justify-center.align-items-center
              button(@click='dirigir_a("servicios")').boton.primario
                h3.white--text Ver más
    video( controls  width='100%' v-scrollanimation video='true' autoplay height='auto').pa-0
      source(:src='video')
    
    v-img(:src='Inicio.imagenTestimonios' width='100%' height='1170px' gradient="to top right, transparent , black").mt-0
      .bloque-testimonios
        .texto-titulo(v-scrollanimation titulo='true' style="top: 5%")
          h2(v-html='Inicio.testimoniosBloque.titulo').white--text
        .texto-resumen(v-scrollanimation style="top: 13%")
          p(v-html='Inicio.testimoniosBloque.resumen').white--text
        .carrusel-testimonios(style="top: 42%")
          v-carousel(v-model='carruselTestimoniosMovil' hide-delimiters :cycle='true' :show-arrows='true' height='100%')
            v-carousel-item(v-for='imagen, i in Inicio.testimonios' :key='`bloque${i}`')
              v-container(fluid).align-center.justify-center.d-flex
                v-img(:src='imagen' max-width='320' height='568')
    
    v-img(:src='Inicio.sobreMi.imagen' width='100%' height='870px' gradient="to top right, transparent , black")
      v-row(v-scrollanimation titulo='true').justify-center.align-center.ma-3.mb-0
        h1.white--text Sobre mí
      v-row.justify-center.align-center().mr-4.ml-4
        .fila(style='text-align: center')
          p(v-html='SobreMi.resumen' v-scrollanimation).white--text
      v-row.justify-center.align-center.mb-6
          button(v-scrollanimation boton='true' @click='dirigir_a("sobreMi")' 
          style='transition-duration: .4s; width: 130px; height: 57px; border-radius: 25px').boton.primario
            h3.white--text Ver más
</template>
<script>
import video from '../../../../assets/paola-vidente-video.mp4'
export default {
  data: ()=>({
    video,
    valor_contacto: 0,
    valor: 0,
    botones: [
      {posicion: 'izquierdo', icono: 'fas fa-chevron-left'},
      {posicion: 'derecho', icono: 'fas fa-chevron-right'},
    ],
    botones_contacto: [
      {posicion: 'izquierdo', icono: 'far fa-arrow-alt-circle-left'},
      {posicion: 'derecho', icono: 'far fa-arrow-alt-circle-right'},
    ],
    carruselTestimonios: 0,
    carruselTestimoniosMovil: 0,
    dialog: false,
    urlDialog: '',
  }),
  methods: {
    cambiarPosicion(posicion){
      if(posicion==='izquierdo') this.valor = this.valor - 1
      else this.valor = this.valor + 1
    },
    cambiarPosicionContacto(posicion){
      if(posicion==='izquierdo') this.valor_contacto = this.valor_contacto - 1
      else this.valor_contacto = this.valor_contacto + 1
    },
    abrirDialog(url){
      this.urlDialog = url
      this.dialog = true
    },
  },
  computed: {
    testimonios(){
      let _testimonios = this.Inicio.testimonios
      return this.separar_arreglo(_testimonios, 3)
    }
  }
}
</script>

<style lang="sass" scoped>
.fila-texto
  position: absolute
  display: flex
  top: 30%
  left: 0
  right: 0
  margin-left: auto
  margin-right: auto
  text-align: center
  h1
    font-size: 4em
    font-weight: bold
  h2
    font-size: 2em
  &[movil='true']
    position: absolute
    display: flex
    top: 40%
    left: 0
    right: 0
    margin-left: auto
    margin-right: auto
    text-align: center
    h3
      font-size: 2em
  &[movil-subtitulo='true']
    position: absolute
    display: flex
    width: 300px
    top: 60%
    left: 0
    right: 0
    margin-left: auto
    margin-right: auto
    text-align: center
    h3
      font-size: 2em

  &[boton-movil='true']
    position: absolute
    top: 77%
    left: 0
    right: 0
    margin-left: auto
    margin-right: auto
    .boton
      transition-duration: .4s
      width: 120px
      height: 47px
      border-radius: 25px
  &[subtitulo='true']
    margin-top: 50px
    width: 300px
    top: 50%
    left: 0
    right: 0
    margin-left: auto
    margin-right: auto
    h1
      font-size: 3em
      line-height: 50px
  &[boton='true']
    top: 77%
    left: 0
    right: 0
    margin-left: auto
    margin-right: auto
    .boton
      transition-duration: .4s
      width: 310px
      height: 87px
      border-radius: 25px
    
.bloque-video
  width: 100%
  height: 700px
  .centrar-columna
    position: absolute
    top: 40%
    right: 10%
    width: 600px
    text-align: center
    h1
      font-size: 3em
.bloque-sobre-mi
  .texto-titulo
    position: absolute
    top: 10%
    left: 0
    right: 0
    margin-left: auto
    margin-right: auto
    text-align: center
  .texto-resumen
    padding: 0 15% 0 15%
    position: absolute
    top: 25%
    text-align: center
    p
      font-size: 20px
  .fila-boton
    position: absolute
    top: 78%
    left: 0
    right: 0
    margin-top: 30px
    margin-left: auto
    margin-right: auto
    width: 300px
    .boton
      left: 0
      right: 0
      margin-left: auto
      margin-right: auto
      transition-duration: .4s
      width: 310px
      height: 87px
      border-radius: 25px

.bloque-testimonios
  .texto-titulo
    position: absolute
    top: 10%
    left: 0
    right: 0
    margin-left: auto
    margin-right: auto
    text-align: center
  .texto-resumen
    padding: 0 15% 0 15%
    position: absolute
    top: 22%
    text-align: center
    p
      font-size: 20px
  .carrusel-testimonios
    position: absolute
    top: 30%
    left: 0
    right: 0
    margin-top: 30px
    margin-left: auto
    margin-right: auto
  .texto-llamado-a-accion
    position: absolute
    top: 87%
    left: 0
    right: 0
    margin-left: auto
    margin-right: auto
    text-align: center

.before-enter
  opacity: 0
  transform: translateY(100%)
  transition: all 1.5s ease-out
  &[video='true']
    transform: translateX(-100%)
  &[titulo='true']
    transform: translateX(100%)
  &[boton='true']
    transform: scale(0.1, 0.1)
  &[drawer='true']
    transform: translateY(-100%)     
.enter
  opacity: 1
  transform: translateY(0px)
  &[titulo='true']
    transform: translateX(0px)
  &[boton='true']
    transform: scale(1, 1)
  &[video='true']
    transform: translateX(0%)
  &[drawer='true']
    transform: translateY(0%) 
</style>